/*
 * Composant pour les actions js lié a un equipemnt
 */

import Swal from "sweetalert2/dist/sweetalert2";

export default class Equipements {

    constructor($view) {

        this.$view = $view;
        this.Init();
    }

    Init() {
        let self = this;
        window.showDeleteConfirmation = self.window_showDeleteConfirmation;
        window.setVisible = self.window_setVisible;
        window.error_handler = self.window_error_handler
    }

    window_showDeleteConfirmation(e) {
        // Afin d'empécher le click sur le bouton de nous faire remonter en haut de page
        e.preventDefault();

        var grid = $("#equipementsgrid").data("kendoGrid");
        var modelToDelete = grid.dataItem($(e.target).parents('tr'));

        Swal.fire({
            title: "Etes-vous sur de vouloir supprimer cet equipement ?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.isConfirmed) {
                grid.dataSource.remove(modelToDelete);
                grid.dataSource.sync();
            }
        })
    }

    window_setVisible(dataItem) {
        // return true or false dependent on the dataItem field value
        return dataItem.EquipementsLinked;
    }

    // gere les erreurs de la grille
    window_error_handler(e) {
        if (e.errors) {
            var message = "Errors:\n";
            $.each(e.errors, function (key, value) {
                if ('errors' in value) {
                    $.each(value.errors, function () {
                        message += this + "\n";
                    });
                }
            });
            alert(message);
        }
    }
}