/*
 * Composant pour les actions js lié a un equipemnt
 */

import Swal from "sweetalert2/dist/sweetalert2";

export default class PolesServices {

    constructor($view) {

        this.$view = $view;
        this.Init();
    }

    Init() {
        let self = this;
        window.showDeleteConfirmation = self.window_showDeleteConfirmation;
        window.setVisible = self.window_setVisible;
    }

    window_showDeleteConfirmation(e) {
        // Afin d'empecher le click sur le bouton de nous faire remonter en haut de page
        e.preventDefault();

      
        let grid = null;
        let titreModal = "";
        if (e.delegateTarget.id == "polesgrid") {
            grid = $("#polesgrid").data("kendoGrid");
            titreModal = "Etes-vous sur de vouloir supprimer ce pôle ?";
        } else {
            grid = $("#servicesgrid").data("kendoGrid");
            titreModal = "Etes-vous sur de vouloir supprimer ce service ?";
        }
        var modelToDelete = grid.dataItem($(e.target).parents('tr'));

        Swal.fire({
            title: titreModal,
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.isConfirmed) {
                grid.dataSource.remove(modelToDelete);
                grid.dataSource.sync();
            }
        })
    }

    window_setVisible(dataItem) {
        // return true or false dependent on the dataItem field value
        return dataItem.FormsLinked;
    }
}